import { apiGetService, apiPostService, apiPutService } from './ApiService';

export const createAgent = async (payload) => {
  return await apiPostService('/account/agents/', payload);
};

export const getAgents = async (payload) => {
  return await apiGetService('/account/agents/', payload);
}

export const updateAgent = async (agent_id, payload) => {
  return await apiPutService(`/account/agents/${agent_id}/`, payload);
}

export const getAgentData = async (agent_id) => {
  return await apiGetService(`/account/agents/${agent_id}/`);
}