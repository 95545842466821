import { v4 as uuidv4 } from 'uuid';
import { useRef } from "react";
import { Button, Input } from "react-daisyui";


export default function ImageComponent({ defaultValues, onChangeHandler, multipleImages }) {
  const imageFileInput = useRef();

  const imageFilesChangeHandler = async (event) => {
    const fileObjs = Array.from(event.target.files);
    const base64Files = [];
    for (const fileObj of fileObjs) {
      try {
        const base64File = await convertToBase64(fileObj);
        const uniqueId = uuidv4();
        base64Files.push({
          id: uniqueId,
          src: base64File.base64String,
          name: base64File.fileName
        });
      } catch (error) {
        console.error(error);
      }
    }

    onChangeHandler(() => ({ ...defaultValues, imageFiles: [...defaultValues.imageFiles, ...base64Files] }))
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({
        base64String: reader.result,
        fileName: file.name
      });
      reader.onerror = error => reject(error);
    });
  }

  const imageFilesRemoveHandler = (id) => {
    onChangeHandler(() => ({ ...defaultValues, imageFiles: defaultValues.imageFiles.filter(file => file.id !== id) }))
  }

  const imageLinksRemoveHandler = (id) => {
    onChangeHandler(() => ({
      ...defaultValues,
      imageLinks: defaultValues.imageLinks.filter(file => file.id !== id),
      removedImageLinks: [...defaultValues.removedImageLinks, defaultValues.imageLinks.find(item => item.id === id)]
    }))
  }

  return (
    <div className="flex w-full p-4 mt-4 items-center gap-2">
      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Upload Images</span>
        </label>
        <Button onClick={(e) => { e.preventDefault(); imageFileInput.current.click(); }} className="border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700"  >
          {defaultValues.imageFiles.length > 0 ? `${defaultValues.imageFiles.length} file(s) selected` : 'Choose files'}
        </Button>
        <Input type="file" ref={imageFileInput} onChange={imageFilesChangeHandler} style={{ display: 'none' }} accept="image/*" {...(multipleImages ? { multiple: true } : {})}
        />
      </div>

      <div className="flex w-full gap-2">
        {defaultValues.imageLinks.map(imageLink => (
          <div key={`image-link-${imageLink.id}`} className="relative">
            <img src={imageLink.image} alt="" className="h-20 w-20 object-cover" />
            <button
              type="button"
              onClick={() => imageLinksRemoveHandler(imageLink.id)}
              className="absolute -right-1 -top-1 bg-red-600 p-2 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs"
            >
              X
            </button>
          </div>
        ))}

        {defaultValues.imageFiles.map(imageFile => (
          <div key={`image-file-${imageFile.id}`} className="relative">
            <img src={imageFile.src} alt="" className="h-20 w-20 object-cover" />
            <button
              type="button"
              onClick={() => imageFilesRemoveHandler(imageFile.id)}
              className="absolute -right-1 -top-1 bg-red-600 p-2 text-white rounded-full h-6 w-6 flex items-center justify-center text-xs"
            >
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}