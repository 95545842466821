import { apiGetService } from './ApiService';

export const getStates = async () => {
  return await apiGetService('/location/divisions/?division_type=state');
}

export function getSlugFromName(name) {
  // convert a name to a slug
  return name.toLowerCase().replace(/ /g, '-');
}

export const getDivisionHierarchy = async (params) => {
  return await apiGetService(`/location/divisions/state_config/`, params);
}

export const getDivisions = async (params) => {
  return await apiGetService('/location/divisions/', params);
}

const fetchAllPages = async (uri, params) => {
  let results = [];
  let page = 1;
  let hasNextPage = true;

  while (hasNextPage) {
    const response = await apiGetService(uri, { ...params, page });
    results = results.concat(response.results);
    hasNextPage = response.next !== null;
    page += 1;
  }
  console.log(results);

  return results;
}

export const getAllHighways = async () => {
  return await fetchAllPages('/tags/', { tag_type: 'highway' });
}

export const getAllTags = async (params) => {
  return await fetchAllPages('/tags/', params);
}
