import { Button } from "react-daisyui";
import { plusIcon } from "../utils/icons";


export default function NavbarComponent() {

  return (
    <div className="flex border-2 p-5 justify-between bg-base-100">
      <a className="text-3xl" href="/lands-dashboard">
        {/* <img src="https://oneacre-uhdvi.ondigitalocean.app/static/images/icons/logo.svg" /> */}
        <img src="https://www.1acre.in/static/images/icons/logo.svg" />
      </a>
      <div className="flex gap-2">
        <Button color="primary" onClick={() => window.location.href = "/lands-dashboard/"}>Lands</Button>

        <Button color="primary" onClick={() => window.location.href = "/plots-dashboard/"}>Plots</Button>
        <Button color="primary" onClick={() => window.location.href = "/agents-dashboard/"}>Agents</Button>
        <Button startIcon={plusIcon} color="primary" onClick={() => window.location.href = "/lands/"}>Add Land</Button>
        <Button startIcon={plusIcon} color="primary" onClick={() => window.location.href = "/plots/"}>Add Plot</Button>
        <Button startIcon={plusIcon} color="primary" onClick={() => window.location.href = "/agents"}>Add Agents</Button>
        <Button color="primary" onClick={() => window.location.href = "/logout"}>Logout</Button>
        <Button color="primary" onClick={() => window.location.href = "/queries"}>Queries</Button>
      </div>
    </div>
  )
}