import HomePage from './pages/HomePage';
import LandPage from './pages/LandPage';
import PlotPage from './pages/PlotPage';
import DashboardPage from './pages/DashboardPage';
import PlotsDashboardPage from './pages/PlotsDashboardPage';
import MapMarkersPage from './pages/MapMarkersPage';
import LogoutPage from './pages/LogoutPage';
import Queries from './pages/queries';
import AgentsPage from './pages/AgentsPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import AgentsDashboardPage from './pages/AgentsDashboardPage';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/lands-dashboard" element={<DashboardPage />} />
          <Route path="/plots-dashboard" element={<PlotsDashboardPage />} />
          <Route path="/agents-dashboard" element={<AgentsDashboardPage />} />
          <Route path="/lands/:land_id?" element={<LandPage />} />
          <Route path="/plots/:plot_id?" element={<PlotPage />} />
          <Route path="/agents/:agent_id?" element={<AgentsPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/map/" element={<MapMarkersPage />} />
          <Route path="/queries/" element={<Queries />} />
          <Route path="/private-map/" element={<MapMarkersPage />} />
          <Route path="/agents" element={<AgentsPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
